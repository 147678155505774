@import '../../theme/newVariables';

.myAccountLabel {
  font-family: $font-az-helvetica;
  font-size: $font-size-12;
  color: $az-black;
  white-space: nowrap;

  @media #{$medium-only} {
    font-size: $font-size-10;
    position: relative;
    top: $az-spacing-neg-1;
  }

  @media #{$small-and-down} {
    font-size: $font-size-10;
  }
}

.myAccountContainer {
  position: relative;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  color: $az-grey-4;
  justify-content: flex-end;

  @media #{$small-and-down} {
    flex-direction: column;
  }

  @media #{$medium-only} {
    font-size: $font-size-12;
    padding-top: $az-spacing-zero;
  }

  .nonSigninArrow {
    position: relative;
    margin-right: $az-spacing-6;
    width: $az-spacing-xxxs;
  }

  .signedInArrow {
    position: relative;
    top: $az-spacing-zero;
    width: $az-spacing-xxxs;
  }

  .myAccountSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: $az-spacing-zero;
    border: none;
    background: transparent;
    letter-spacing: 0.25px;
  }

  .myAccountUserIcon {
    height: $az-spacing-xs;
    width: $az-spacing-xs;
    margin: auto;

    @media #{$medium-only} {
      height: $az-spacing-xxs;
      width: $az-spacing-xxs;
    }
  }

  .userIcon {
    flex-direction: row;
    display: flex;
    margin: $az-spacing-zero $az-spacing-4xs;
  }

  .signInDotSticky::before {
    left: 40%;
    top: 12px;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background: #f26100;
    padding: 3px;
    position: absolute;
    content: '';
  }

  .label {
    margin-left: 10px;
  }

  .userIconImg {
    height: 20px;
    width: 20px;
    margin: $az-spacing-zero auto;
  }

  .myAccountUnAuth {
    flex-direction: row;
    padding: $az-spacing-4xs;

    .userIcon {
      flex-direction: column;
    }
  }

  .myAccountAuth {
    flex-direction: row;
    padding: $az-spacing-4xs;

    .userIcon {
      flex-direction: row;
      align-items: center;
    }

    .myAccountUserIcon {
      margin-right: $az-spacing-xxs;
    }

    .myAccountUsername {
      margin-left: $az-spacing-xxs;
      letter-spacing: 0.25px;
    }

    .myAccountReward {
      color: $az-color-success;
      font-weight: bold;
      line-height: 1.17;
    }
  }

  .myAccountStickyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px;

    .myAccountStickyIcons {
      display: flex;
      justify-content: center;
      align-items: center;

      .userIconSticky {
        display: flex;
        height: auto;
        width: 16.7px;
      }
      .arrowIconSticky {
        display: flex;
        padding-left: 4.3px;

        .arrowImage {
          width: 100%;
          height: auto;
        }
      }
    }

    .myAccountLabelSticky {
      font-family: $font-az-helvetica;
      font-size: 12px;
      color: $az-black;
      line-height: 12px;
      padding-top: 2.33px;
    }
  }
}

.fixedHeaderOffset {
  transform: translateY($az-spacing-17);
}

.fixedHeaderOffsetMX {
  transform: translateY($az-spacing-5xs);
}

.topHeaderOffset {
  transform: translateY($az-spacing-14);
}

.topHeaderOffsetMX {
  transform: translateY($az-spacing-5xs);
}

.myAccountStickyMainContainer {
  padding: 8px 16px;
}

.error {
  color: $az-light-red;
}

.arrow {
  @media (min-width: $large-screen-up) {
    margin-bottom: 3px;
  }
}

.accountPopup {
  background: $az-white;
  position: absolute;
  top: 81px;
  right: $az-spacing-neg-60;
  bottom: auto;
  width: $az-spacing-370;
  z-index: 1900;
  box-shadow: $az-spacing-zero $az-spacing-3 $az-spacing-14 $az-spacing-2 rgba(32, 33, 36, 0.12),
    $az-spacing-zero $az-spacing-4xs $az-spacing-10 $az-spacing-1 rgba(32, 33, 36, 0.14),
    $az-spacing-zero $az-spacing-5 $az-spacing-5 $az-spacing-neg-3 rgba(32, 33, 36, 0.2);

  @media #{$medium-only} {
    top: 67px;
    right: -48px;
  }
}

.accountPopupUserAuthenticated {
  background: $az-white;
  position: absolute;
  right: -84px;
  top: 73px;
  width: $az-spacing-380;
  z-index: 1900;
  box-shadow: $az-spacing-zero $az-spacing-3 $az-spacing-14 $az-spacing-2 rgba(32, 33, 36, 0.12),
    $az-spacing-zero $az-spacing-4xs $az-spacing-10 $az-spacing-1 rgba(32, 33, 36, 0.14),
    $az-spacing-zero $az-spacing-5 $az-spacing-5 $az-spacing-neg-3 rgba(32, 33, 36, 0.2);

  @media #{$medium-only} {
    top: 60px;
    right: -68px;
  }
}

.accountPopupArrowGray::before {
  background-color: $az-grey-1 !important;
}

.popupArrow::before,
.popupArrowAuthenticated::before,
.accountPopupArrowGray::before {
  position: absolute;
  content: '';
  top: $az-spacing-neg-6;
  width: $az-spacing-xxxs;
  height: $az-spacing-xxxs;
  background: $az-white;
  transform: rotate(45deg);
  z-index: 2;
}

.popupArrow::before {
  right: 68px;

  @media #{$medium-only} {
    right: 56px;
  }
}

.popupArrowAuthenticated::before {
  right: 92px;

  @media #{$medium-only} {
    right: 76px;
  }
}

.myAccountListItem {
  padding-bottom: 0px !important;
  cursor: pointer;
  display: block;
}

.listItem {
  cursor: pointer;
  padding: $az-spacing-4xs $az-spacing-xs !important;
  display: block;
  width: $az-percent-100;
}

.listItemUserAuthenticated {
  cursor: pointer;
  padding: 6.5px $az-spacing-xxs !important;
  display: block;
  width: $az-percent-100;
}

.listItemFirst {
  @extend .listItemUserAuthenticated;
  padding-top: 13px !important;
}

.listItemLast {
  @extend .listItemUserAuthenticated;
  padding-bottom: 13px !important;
}

.listItemSignOut {
  @extend .listItemFirst;
  padding-bottom: 16px !important;
  text-decoration: none;
  text-align: initial;
  background: none;
  border: none;
}

.listItem:hover,
.listItemUserAuthenticated:hover,
.listItemSignOut:hover,
.listItemFirst:hover,
.listItemLast:hover,
.myAccountListItem:hover,
.anchorTag:hover {
  background-color: $az-grey-1;
  color: $az-black;
}

.listItemHoverState:hover {
  background-color: $az-grey-1;
  color: $az-black;
  text-decoration: underline;
}

.menuFooterNeedHelp {
  width: 120px;
}

.menuFooterNeedHelpMX {
  width: 154px;
}

.menuFooterOption {
  display: flex;
  height: 58px;
}

.needHelpBtn {
  padding-left: 16px;
}

.signOutBtn {
  width: auto;
  padding-right: 16px;
  padding-left: 16px;
  align-items: center;
}

.line {
  border: none;
  border-top: $az-spacing-1 solid $az-grey-2;
  margin: 0px !important;
}

.fullWidth {
  width: 100%;
}

.rewardsBalance {
  color: #157400 !important;
  @media #{$small-and-down} {
    line-height: 19.6px !important;
  }
}

.rightArrow {
  transform: rotate(-90deg);
}

.signInV2Button:focus-visible {
  outline: 3px solid var(--st-color-focus);
  outline-offset: 2px !important;
}

.anchorTag {
  padding-bottom: 0px !important; // Overrides src/features/header/components/TopHeader/styles.module.scss
}

.actionableOneLine {
  white-space: nowrap;
}

.actionableNoPadding {
  padding-bottom: 0px !important;
  line-height: 24px !important;
  @media #{$small-and-down} {
    line-height: 20px !important;
    font-size: $font-size-14;
  }
}

.starcOverrideTextVariantDisplay5 {
  font-size: 16px;
}

.starcTextVariantOverrideSmallBody {
  line-height: 22px;
  color: rgba(110, 110, 110, 1);
}

.bold > div {
  font-weight: 700 !important;
}

.salutation {
  font-family: var(--st-text-font-family, var(--st-font-family-body));
  font-size: 16px;
  color: #000;
  line-height: 24px;
  font-weight: 700;
  font-style: normal;

  @media #{$small-and-down} {
    font-size: 14px;
    line-height: 20px;
  }
}
